
import { defineComponent } from "vue";

import CompanyCatalogue from "@/components/catalogues/CompanyCatalogue/CompanyCatalogue.vue";

export default defineComponent({
  components: { CompanyCatalogue },
  props: {},
  methods: {},
  data() {
    return {};
  },
});
